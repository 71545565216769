body {
  margin: 0;
  padding: 0;
  font-family: Dogica, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #ffffff;
}

body * {
  margin: 0;
  padding: 0;
}

p {
  letter-spacing: -3px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
}

header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 5px;
  padding: 0 10vw;
  background-color: rgb(2, 17, 0);
  height: clamp(80px, 15vw, 100px);
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socials ul {
  display: flex;
}

.socials li {
  margin: 0 .4em;
}

.socials img {
  width: 2.2em;
  height: 2.2em;
}

.twitter {
  padding-right: 0;
}

.logo {
  font-family: "Game Of Squids";
  font-weight: normal;
  font-style: normal;
  margin: auto;
}

.icon {
  width: 40px;
}

.hero {
  height: 100vh;
  width: 100%;
  background-image: url(gorilla.png);
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat
}


.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50vh;
  height: auto;
}

.timer-text {
  color: rgb(221, 221, 0);
  font-family: "Game Of Squids";
  font-size: 3em;
  white-space: nowrap;
  text-shadow: 2px 2px black;
}

.mint-details {
  background-color: rgb(194, 156, 50);
  border: 2px solid white;
  border-radius: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: clamp(420px, 60%, 850px);
}

.item {
  padding: 10px;
  line-height: 22px;
  height: 100%;
}

.item2 {
  border-width: 0 2px;
  border-color: white;
  border-style: solid;
}

.nobody-reads {
  height: 20vh;
  font-family: "Dogica";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: rgb(2, 17, 0);
}

.connected-wallet {
  font-family: Dogica;
  display: flex;
  align-items: center;
}

.connected-icon {
  border-radius: 100%;
  width: 0.6em;
  height: 0.6em;
  background-color: rgb(1, 158, 1);
  margin-right: 0.5em;
}

.mint-container {
  margin: auto;
  text-align: center;
  margin-top: 80px;
  display: flex;
}

.call-to-action {
  font-family: "Game Of Squids";
  color: yellow;
  text-shadow: 2px 2px black;
}

@media screen and (max-width: 650px) {
  .hero {
    background-position: 63% 0;
  }

  .header {
    padding: 0;
    margin: 0;
  }
  
  .timer-text {
    font-size: 2.2em;
  }

  .nobody-reads {
    font-size: .8em;
  }
  
}

@media screen and (min-aspect-ratio: 3122/1750) {
  .hero{
    background-size: 100% auto;
  }
}